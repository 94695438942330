"use client";

import { Minus24, Plus24 } from "@/components/icons";
import { clsx } from "@frend-digital/ui";
import { useTranslations } from "next-intl";
import { IconButton } from "../iconButton";
import styles from "./index.module.css";

export const Counter = ({
	className,
	value,
	action,
}: {
	className?: string;
	value: number;
	// eslint-disable-next-line no-unused-vars
	action: (value: number) => void;
}) => {
	const t = useTranslations("counter");
	return (
		<fieldset className={clsx(styles.root, className)}>
			<legend className="sr-only">{t("quantity")}</legend>

			<IconButton
				className={styles.icon}
				onClick={() => action(value - 1 < 0 ? 0 : value - 1)}
				aria-label="Decrease"
				size="small"
				color="darkblue">
				<Minus24 />
			</IconButton>

			<output className={styles.output}>
				<span>{value}</span>
			</output>

			<IconButton
				className={styles.icon}
				onClick={() => action(value + 1)}
				aria-label="Increase"
				size="small"
				color="darkblue">
				<Plus24 />
			</IconButton>
		</fieldset>
	);
};
