import type { SVGProps } from "react";

export const Refresh = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M17 6.5a7 7 0 1 0 2.47 5.29M17 3v3.5H13.5"
			/>
		</svg>
	);
};
