import BlokWrap from "@/components/bloks/BlokWrap";
import { RichTextRenderer } from "@/components/ui/RichText";
import { Text } from "@/components/ui/Text";
import type { RichTextStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import { clsx } from "@frend-digital/ui";
import styles from "./index.module.css";

export const RichTextBlok = ({ blok, preview }: BlokProps<RichTextStoryblok>) => {
	return (
		<BlokWrap data-layout="content" editable={blok} as="div" className={styles.root}>
			{blok.title && (
				<Text variant="h3" className={styles.title} bold asChild>
					<h2>{blok.title}</h2>
				</Text>
			)}
			{blok.text && (
				<RichTextRenderer
					className={clsx(styles.text, blok.center && styles.center)}
					blok={blok.text}
					preview={preview}
				/>
			)}
		</BlokWrap>
	);
};
