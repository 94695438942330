import type { ComponentProps } from "react";
import type { FetchProductsReturn } from "../../actions";
import { InfiniteProductView } from "./ProductView";

type InifinateProductViewLoaderProps = Omit<
	ComponentProps<typeof InfiniteProductView>,
	"initialData"
> & {
	dataPromise: Promise<FetchProductsReturn>;
};

export const InifinateProductViewLoader = async ({
	dataPromise,
	...props
}: InifinateProductViewLoaderProps) => {
	const data = await dataPromise;

	return <InfiniteProductView initialData={data} {...props} />;
};
