import { createNavigation } from "next-intl/navigation";
import { defineRouting } from "next-intl/routing";

const LOCALES = ["en", "no"] as const;

export const DEFAULT_LOCALE = "en" as const;

export type LocaleProps<P extends {} = {}, Params extends {} = {}> = {
	params: Promise<
		{
			locale: (typeof LOCALES)[number];
		} & Params
	>;
} & P;

export { LOCALES };

export const routing = defineRouting({
	defaultLocale: DEFAULT_LOCALE,
	locales: LOCALES,
});

export const { Link, getPathname, permanentRedirect, redirect, usePathname, useRouter } =
	createNavigation(routing);
