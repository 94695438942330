import { useGeoData } from "@/features/cart/hooks";
import { useFreeShippingVouchers } from "@/features/Checkout/hooks";

export const useFreeShippingCost = () => {
	const {
		data: freeShippingVouchers,
		isLoading: loadingVouchers,
		error: vouchersError,
	} = useFreeShippingVouchers();
	const { data: geoData, isLoading: loadingGeoData, error: geoDataError } = useGeoData();

	const isLoading = loadingGeoData || loadingVouchers;

	if (isLoading) {
		return {
			isLoading,
		};
	}

	const error = geoDataError || vouchersError;

	if (error) {
		return {
			error,
		};
	}

	const freeShippingCriteria = JSON.parse(
		freeShippingVouchers?.at(0)?.actions?.at(0)?.criteria?.at(1)?.paramsJSON || "{}",
	);

	const freeShippingCost = freeShippingCriteria.value[
		Number(geoData?.pricelist)
	] as number;

	return {
		freeShippingCost,
		freeShippingCurrency: geoData?.currency,
	};
};
