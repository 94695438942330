"use client";

import type { SliderProducts } from "@/components/bloks/ProductSliderBlok/actions";
import { SliderTabBar } from "@/components/bloks/ProductSliderBlok/SliderTabBar";
import { ProductCarousel } from "@/features/products/ProductCarousel";
import { useState } from "react";
import { FirstWordBold } from "../FirstWordBold";
import { Text } from "../Text";
import styles from "./index.module.css";

export interface ProductSliderProps {
	title?: string;
	sections: SliderProducts;
}

export const ProductSlider = ({ title, sections }: ProductSliderProps) => {
	const [activeTab, setActiveTab] = useState<string>(sections?.[0]?.title || "");

	const products = sections?.flatMap((section) => section.products);

	if (!products) return null;

	const displayProducts = activeTab
		? sections?.find((section) => section.title === activeTab)?.products
		: products;

	const tabs = sections
		?.map((section) => {
			return {
				name: section.title,
				value: section.title,
			};
		})
		.filter(Boolean);
	return (
		<div className={styles.root}>
			<div className={styles.header}>
				{title && (
					<Text variant="h3" className={styles.title} data-layout="page">
						<FirstWordBold>{title}</FirstWordBold>
					</Text>
				)}
				<SliderTabBar activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
			</div>
			{sections && (
				<ProductCarousel
					options={{ slidesToScroll: 1, align: "start" }}
					products={displayProducts ?? []}
				/>
			)}
		</div>
	);
};
