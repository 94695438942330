import BlokWrap from "@/components/bloks/BlokWrap";
import { Text } from "@/components/ui/Text";
import type { QouteStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import styles from "./index.module.css";

export const QuoteBlok = ({ blok }: BlokProps<QouteStoryblok>) => {
	return (
		<BlokWrap layout="content" editable={blok} asChild className={styles.root}>
			<Text asChild>
				<blockquote>{blok.text}</blockquote>
			</Text>
		</BlokWrap>
	);
};
