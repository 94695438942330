import BlokWrap from "@/components/bloks/BlokWrap";
import { ProductSlider } from "@/components/ui/ProductSlider";
import type { ProductSliderStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import type { SliderProducts } from "./actions";

export const ProductSliderBlok = ({
	blok,
	data,
}: BlokProps<ProductSliderStoryblok, SliderProducts>) => {
	return (
		<BlokWrap layout="full" editable={blok} as="div">
			{data && <ProductSlider title={blok.title} sections={data} />}
		</BlokWrap>
	);
};
