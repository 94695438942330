import BlokWrap from "@/components/bloks/BlokWrap";
import { Button } from "@/components/ui/button";
import type { ButtonStoryblok } from "@/generated/sb/sb-types";
import { getLink, type BlokProps } from "@frend-digital/storyblok";
export const ButtonBlok = ({ blok }: BlokProps<ButtonStoryblok>) => {
	const link = getLink(blok.link);

	return (
		<BlokWrap editable={blok} asChild>
			<Button href={link?.href}>{blok.text}</Button>
		</BlokWrap>
	);
};
