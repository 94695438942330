import BlokWrap from "@/components/bloks/BlokWrap";

import { ContentCard } from "@/components/ui/ContentCard";
import type { ContentCardStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import styles from "./index.module.css";

export const ContentCardBlok = ({ blok }: BlokProps<ContentCardStoryblok>) => {
	if (!blok.asset) return;
	return (
		<BlokWrap editable={blok} className={styles.root} dataBlok={false}>
			<ContentCard title={blok.title} storyLink={blok.storyLink} asset={blok.asset} />
		</BlokWrap>
	);
};
