import { Arrow24 } from "@/components/icons";
import { clsx } from "@frend-digital/ui";
import Image from "next/image";
import { type ComponentProps } from "react";
import { Button } from "../button";
import styles from "./index.module.css";

type CategoryButtonProps = ComponentProps<typeof Button> & {
	text?: string;
	imageSource?: string;
	href?: string;
	pointer?: boolean;
};

export const CategoryButton = ({
	text,
	imageSource,
	pointer,
	...props
}: CategoryButtonProps) => {
	return (
		<Button
			prefetch
			{...props}
			className={clsx(
				styles.root,
				props.className,
				props.color && styles.bypassColor,
				!imageSource && styles.evenPadding,
			)}>
			<div className={styles.main}>
				{imageSource && (
					<div className={styles.categoryImage}>
						<Image src={imageSource} alt="" width={24} height={30} />
					</div>
				)}
				<span className={styles.text}>{text}</span>
			</div>
			{pointer && <Arrow24 height={24} width={24} />}
		</Button>
	);
};
