"use client";

import { createContext, useContext } from "react";

export const SideBySideContext = createContext(false);

export const SideBySideProvider = ({ children }: { children: React.ReactNode }) => {
	return <SideBySideContext.Provider value={true}>{children}</SideBySideContext.Provider>;
};

export function useSideBySide() {
	return useContext(SideBySideContext);
}
