"use client";

import { CountryFlag } from "@frend-digital/centra/location";
import {
	clsx,
	SelectContent,
	SelectIndicator,
	SelectItem,
	SelectLabel,
	SelectReset,
	SelectRoot,
	SelectTrigger,
	SelectValue,
	SelectViewport,
} from "@frend-digital/ui";
import { useTranslations } from "next-intl";
import React, {
	forwardRef,
	useDeferredValue,
	useMemo,
	useState,
	type ComponentProps,
	type HTMLAttributes,
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Button, type ButtonColor } from "../button";
import { ScrollAreaBar, ScrollAreaRoot, ScrollAreaViewport } from "../ScrollArea";
import { Text } from "../Text";
import styles from "./index.module.css";

type Item = {
	name: string;
	value: string;
};

type SelectProps = {
	items: Item[];
	onValueChange?: (e: string) => void;
	value?: string | null;
	placeholder?: string;
	label?: React.ReactNode;
	color?: ButtonColor;
	hideReset?: boolean;
	resetPlaceholder?: string;
	name?: string;
	defaultValue?: string;
	renderItem?: (item: Item) => React.ReactNode;
	renderLabel?: () => React.ReactNode;
	large?: boolean;
	className?: string;
	error?: string;
	children?: React.ReactNode;
};

export const Select = ({
	items,
	onValueChange,
	placeholder,
	label,
	color,
	hideReset = true,
	resetPlaceholder,
	name,
	defaultValue,
	value,
	renderItem,
	renderLabel,
	large,
	className,
	error,
	children,
}: SelectProps) => {
	const t = useTranslations("select");
	return (
		<SelectRoot
			onValueChange={onValueChange}
			name={name}
			value={value || undefined}
			defaultValue={value ?? defaultValue}>
			<div className={clsx(styles.root, large && styles.large, className)}>
				{(label || renderLabel) && !error && (
					<SelectLabel asChild>
						{renderLabel ? (
							renderLabel()
						) : (
							<Text variant="utility5" color="primary" className={styles.label}>
								{label}
							</Text>
						)}
					</SelectLabel>
				)}

				{error && (
					<SelectLabel asChild>
						<Text variant="utility5" color="red" className={styles.label}>
							{error}
						</Text>
					</SelectLabel>
				)}
				<SelectTrigger asChild>
					<Button
						type="button"
						variant={color ? "default" : "unstyled"}
						color={color}
						className={styles.primitive}>
						<div className={clsx(color && styles.muted, styles.trigger)}>
							<SelectValue placeholder={placeholder} />
						</div>
						<SelectIndicator />
					</Button>
				</SelectTrigger>

				<SelectViewport className={styles.options}>
					<ScrollAreaRoot className={styles.scrollRoot}>
						<ScrollAreaViewport className={styles.scrollViewport}>
							{!hideReset && (
								<div className={styles.option}>
									<SelectReset asChild>
										<Text variant="utility4">{resetPlaceholder ?? t("reset")}</Text>
									</SelectReset>
								</div>
							)}
							<SelectContent>
								{children}
								{items.map((item, i) => (
									<SelectItem asChild key={i} value={item.value}>
										{renderItem ? (
											renderItem(item)
										) : (
											<div className={styles.option}>
												<div className={styles.radioButton} />
												<Text variant="utility4">{item.name}</Text>
											</div>
										)}
									</SelectItem>
								))}
							</SelectContent>
						</ScrollAreaViewport>
						<ScrollAreaBar className={styles.bar} orientation="vertical" />
					</ScrollAreaRoot>
				</SelectViewport>
			</div>
		</SelectRoot>
	);
};

export const Combobox = ({
	searchPlaceholder,
	items,
	useValue,
	...props
}: ComponentProps<typeof Select> & { searchPlaceholder: string; useValue?: boolean }) => {
	const [search, setSearch] = useState("");
	const deferredSearch = useDeferredValue(search);

	const filteredItems = useMemo(() => {
		return items.filter((item) => {
			const nameCond = item.name.toLowerCase().includes(deferredSearch.toLowerCase());
			if (useValue)
				return (
					nameCond || item.value.toLowerCase().includes(deferredSearch.toLowerCase())
				);
			return nameCond;
		});
	}, [deferredSearch, items, useValue]);

	return (
		<Select items={filteredItems} {...props}>
			<input
				type="search"
				onChange={(e) => setSearch(e.target.value)}
				className={styles.comboboxInput}
				placeholder={searchPlaceholder}
			/>
		</Select>
	);
};

export const SelectItemWithFlag = forwardRef<
	HTMLDivElement,
	{ item: Item } & HTMLAttributes<HTMLDivElement>
>(({ item, ...props }, ref) => {
	return (
		<div ref={ref} {...props} className={clsx(styles.option, props.className)}>
			<div className={styles.radioButton} />
			<CountryFlag countryCode={item.value!} />
			<Text variant="utility4">{item.name}</Text>
		</div>
	);
});

SelectItemWithFlag.displayName = "SelectItemWithFlag";

interface SelectFieldProps extends Omit<ComponentProps<typeof Select>, "name"> {
	name: string;
}

export const SelectField = ({ name, ...rest }: SelectFieldProps) => {
	const { control } = useFormContext();
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<Select
					error={error?.message}
					{...rest}
					name={field.name}
					value={field.value}
					onValueChange={field.onChange}
				/>
			)}
		/>
	);
};

interface ComboBoxFieldProps extends Omit<ComponentProps<typeof Combobox>, "name"> {
	name: string;
}

export const ComboboxField = ({ items, ...props }: ComboBoxFieldProps) => {
	const { control } = useFormContext();
	return (
		<Controller
			name={props.name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<Combobox
					items={items}
					error={error?.message}
					{...props}
					name={field.name}
					value={field.value}
					onValueChange={field.onChange}
				/>
			)}
		/>
	);
};
