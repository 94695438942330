"use client";

import { CartIcon } from "@/components/icons";
import { AmountDot } from "@/components/ui/AmountDot";
import { Button } from "@/components/ui/button";
import { CartModalTrigger } from "@/features/cart/CartModal";
import { useCartItemCount } from "@/features/cart/hooks";

export const CartButton = ({ className }: { className?: string }) => {
	const count = useCartItemCount();

	const button = (
		<Button variant="unstyled">
			<span className="sr-only">Add to cart</span>
			<CartIcon />
		</Button>
	);

	return (
		<CartModalTrigger className={className} asChild>
			{typeof count === "number" && !!count ? (
				<AmountDot size="notification" variant="yellow" amount={count!}>
					{button}
				</AmountDot>
			) : (
				button
			)}
		</CartModalTrigger>
	);
};
