"use client";
import { useMediaQuery } from "@frend-digital/ui/hooks";
import React, { createContext, useLayoutEffect, useState } from "react";

type Props = {
	children?: React.ReactNode;
};

interface DeviceContext {
	isDesktopXL: boolean;
	isDesktop: boolean;
	isTablet: boolean;
	isMobile: boolean;
	touchEvents: boolean;
}

export const DeviceContext = createContext<DeviceContext>(null!);

const DeviceProvider: React.FC<Props> = ({ children }) => {
	const isDesktopXL = useMediaQuery("(min-width: 1280px)");
	const isDesktop = useMediaQuery("(min-width: 1024px)");
	const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
	const isMobile = useMediaQuery("(max-width: 767px)", { defaultValue: true });
	const [touchEvents, setTouchEvents] = useState(false);

	useLayoutEffect(() => {
		isTouchEnabled() && setTouchEvents(true);
	}, []);

	return (
		<DeviceContext.Provider
			value={{ isDesktopXL, isDesktop, isTablet, isMobile, touchEvents }}>
			{children}
		</DeviceContext.Provider>
	);
};

export default DeviceProvider;

function isTouchEnabled() {
	return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}
