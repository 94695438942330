"use client";

import { usePathname } from "next/navigation";
import type { ReactNode } from "react";
import footerStyles from "./index.module.css";

export const FooterWrapper = ({ children }: { children: ReactNode }) => {
	const path = usePathname();

	if (path.includes("/checkout")) return null;

	return <footer className={footerStyles.root}>{children}</footer>;
};
