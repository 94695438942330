"use client";

import { Settings } from "@/components/icons";
import { Refresh } from "@/components/icons/Refresh";
import { Button } from "@/components/ui/button";
import { Text } from "@/components/ui/Text";
import { TooltipContent, TooltipRoot, TooltipTrigger } from "@/components/ui/Tooltip";

import {
	DialogContent,
	DialogRoot,
	DialogTrigger,
	DialogViewport,
} from "@frend-digital/ui";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useState, useTransition } from "react";
import frendLogo from "./frend-logo.png";
import styles from "./index.module.css";

export const StoryblokToolbar = () => {
	return (
		<aside className={styles.root}>
			<Image
				src={frendLogo}
				alt="frend logo"
				height={256}
				style={{
					height: 40,
					width: "auto",
				}}
			/>
			<span className={styles.separator} />
			<div className={styles.inner}>
				<RefreshPage />
			</div>

			<div className={styles.inner}>
				<SettingsDialog />
			</div>
		</aside>
	);
};

export const SettingsDialog = () => {
	return (
		<DialogRoot>
			<DialogTrigger asChild>
				<Button variant="unstyled">
					<Settings />
				</Button>
			</DialogTrigger>
			<DialogViewport className={styles.modalViewport}>
				<DialogContent className={styles.formStyles}>
					<div className={styles.flex}>
						<h1>Settings</h1>
						<ExitPreviewMode />
					</div>
					<span className={styles.separator} dir=":horizontal" />
				</DialogContent>
			</DialogViewport>
		</DialogRoot>
	);
};

const ExitPreviewMode = () => {
	const [inIframe, setInIframe] = useState(true);
	useEffect(() => {
		if (window.location !== window.parent.location) {
			setInIframe(true);
		} else {
			setInIframe(false);
		}
	}, []);

	const handleExit = useCallback(() => {
		const url = new URL("/api/preview/disable", origin);
		url.searchParams.set("slug", window.location.pathname);
		window.location.href = url.href;
	}, []);

	if (inIframe) return null;

	return (
		<button onClick={handleExit} style={{ marginLeft: "auto" }} className={styles.button}>
			Exit Preview Mode
		</button>
	);
};

const RefreshPage = () => {
	const pending = useIsFetching();
	const [isPending, startTransition] = useTransition();
	const queryClient = useQueryClient();
	const router = useRouter();

	const handleRefresh = () => {
		startTransition(() => {
			router.refresh();
			queryClient.invalidateQueries();
		});
	};
	return (
		<TooltipRoot>
			<TooltipTrigger>
				<Button
					isLoading={isPending || !!pending}
					variant="unstyled"
					onClick={handleRefresh}>
					<Refresh />
				</Button>
			</TooltipTrigger>
			<TooltipContent className={styles.content}>
				<Text variant="utility4">Refresh</Text>
			</TooltipContent>
		</TooltipRoot>
	);
};
