import BlokWrap from "@/components/bloks/BlokWrap";
import { PathBreadcrumbs } from "@/components/ui/PathBreadcrumbs";
import type { PageStoryblok } from "@/generated/sb/sb-types";
import { StoryblokComponentList, type BlokProps } from "@frend-digital/storyblok";

export const PageBlok = ({
	blok,
	preview,
	slug,
	...rest
}: BlokProps<PageStoryblok> & { slug?: string[] }) => {
	return (
		<BlokWrap editable={blok} as="main">
			{blok.top && (
				<>
					{blok.top.every((b) => b.component !== "hero") && <PathBreadcrumbs />}
					<StoryblokComponentList bloks={blok.top} preview={preview} isTop />
				</>
			)}
			{blok.body && (
				<StoryblokComponentList {...rest} bloks={blok.body} preview={preview} />
			)}
		</BlokWrap>
	);
};
