"use client";

import {
	autoPlacement,
	autoUpdate,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
	useRole,
	type UseFloatingReturn,
	type UseInteractionsReturn,
} from "@floating-ui/react";
import { clsx, Slot } from "@frend-digital/ui";
import { useControllableState } from "@frend-digital/ui/hooks";
import {
	createContext,
	use,
	useMemo,
	type ComponentProps,
	type Dispatch,
	type SetStateAction,
} from "react";
import styles from "./index.module.css";

export type RootProps = {};

interface PopoverContext {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	floating: {
		root: UseFloatingReturn;
		interactions: UseInteractionsReturn;
	};
}

const PopoverContext = createContext<PopoverContext>(null!);

export const PopoverRoot = ({
	children,
	open: externalOpen,
	onOpenChange,
}: {
	children: React.ReactNode;
	open?: PopoverContext["open"];
	onOpenChange?: PopoverContext["setOpen"];
}) => {
	const [open, setOpen] = useControllableState(false, externalOpen, onOpenChange);

	const floating = useFloating({
		open: open,
		onOpenChange: setOpen,
		middleware: [
			offset(10),
			autoPlacement(),
			shift({
				padding: 10,
			}),
		],
		whileElementsMounted: autoUpdate,
	});

	const click = useClick(floating.context);
	const dismiss = useDismiss(floating.context);
	const role = useRole(floating.context);

	const interactions = useInteractions([click, dismiss, role]);

	const floatingData = useMemo(() => {
		return {
			root: floating,
			interactions,
		};
	}, [floating, interactions]);

	return (
		<PopoverContext.Provider value={{ open, setOpen, floating: floatingData }}>
			{children}
		</PopoverContext.Provider>
	);
};

export const PopoverTrigger = ({
	children,
	asChild,
	...rest
}: {
	asChild?: boolean;
} & ComponentProps<"button">) => {
	const {
		floating: { interactions, root },
	} = use(PopoverContext);

	const Component = asChild ? Slot : "button";

	return (
		<Component ref={root.refs.setReference} {...interactions.getReferenceProps(rest)}>
			{children}
		</Component>
	);
};

export const PopoverContent = ({
	children,
	asChild,
	...rest
}: {
	asChild?: boolean;
} & ComponentProps<"div">) => {
	const {
		open,
		floating: { root, interactions },
	} = use(PopoverContext);

	const Component = asChild ? Slot : "div";

	return (
		<Component
			data-state={open ? "open" : "closed"}
			ref={root.refs.setFloating}
			{...interactions.getFloatingProps(rest)}
			className={clsx(rest.className, styles.content)}
			style={root.floatingStyles}>
			{children}
		</Component>
	);
};
