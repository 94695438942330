import { use } from "react";
import { DeviceContext } from "./DeviceContext";

export const useDevice = () => {
	const { isDesktopXL, isDesktop, isTablet, isMobile, touchEvents } = use(DeviceContext);

	return {
		desktopLarge: isDesktopXL,
		desktop: isDesktop,
		tablet: isTablet,
		mobile: isMobile,
		touchEvents,
	};
};
export type DeviceType = ReturnType<typeof useDevice>;
