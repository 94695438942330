/* eslint-disable custom-rules/no-hardcoded-strings */

import { Filter } from "@/components/icons";
import { Skeleton } from "@/components/ui/Skeleton";
import { TabBar } from "@/components/ui/TabBar";
import { Text } from "@/components/ui/Text";
import styles from "./index.module.css";

export const FilterBarSkeleton = () => {
	return (
		<div className={styles.filterBar}>
			<Skeleton fitContent className={styles.filterSkeleton}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}>
					<Filter />
					<Text variant="utility3" bold>
						Filter & Sort
					</Text>
				</div>
			</Skeleton>

			<div className={styles.center}>
				<Skeleton fitContent>
					<TabBar
						tabs={[
							{ name: "4 images", value: "four" },
							{ name: "3 images", value: "three" },
						]}
					/>
				</Skeleton>
			</div>
			<Skeleton fitContent>
				<div className={styles.center}>
					<TabBar
						tabs={[
							{ name: "Model", value: "model" },
							{ name: "Product", value: "product" },
						]}
					/>
				</div>
			</Skeleton>
		</div>
	);
};
