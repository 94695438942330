"use client";

import { Button } from "@/components/ui/button";
import { Carousel, CarouselItem } from "@/components/ui/Carousel";
import { Tag } from "@/components/ui/Tag";
import { Text } from "@/components/ui/Text";
import type { ProductCard } from "@/lib/centra/formatters";
import { clsx } from "@frend-digital/ui";
import Image from "next/image";
import { useRef, type ComponentProps } from "react";
import styles from "./index.module.css";

import { PopoverContent, PopoverRoot, PopoverTrigger } from "@/components/ui/Popover";
import { PATHS } from "@/config";
import { useDevice } from "@/features/Device";
import { useClickLinkInside } from "@frend-digital/ui/hooks";
import { Translate } from "move-to-front/ui/Translate";
import { useTranslations } from "next-intl";
import Link from "next/link";

export interface TaggedProductProps extends Omit<ComponentProps<"article">, "children"> {
	product: ProductCard;
	children: React.ReactNode;
}

export const TaggedProduct = ({
	product,
	className,
	children,
	...rest
}: TaggedProductProps) => {
	const t = useTranslations("products.product");
	const linkRef = useRef<HTMLAnchorElement>(null);
	const onClick = useClickLinkInside(linkRef);
	const { mobile } = useDevice();

	return (
		<PopoverRoot>
			<PopoverTrigger asChild>{children}</PopoverTrigger>
			<PopoverContent>
				<article
					onClick={(e) => {
						if (!mobile) {
							onClick(e);
						}
					}}
					{...rest}
					className={clsx(styles.root, className)}>
					<Carousel
						pagination={{
							size: "large",
						}}
						itemsPerSlide="ignore"
						classNames={{
							root: styles.carousel,
							prev: clsx(styles.prev, styles.control),
							next: clsx(styles.next, styles.control),
						}}>
						{product.tags && product.tags[0] && (
							<Tag weight="medium" variant="white" className={styles.tag} size="xSmall">
								{product.tags[0]}
							</Tag>
						)}
						{product.media.map((media) => (
							<CarouselItem className={styles.carouselItem} key={media.href}>
								<Image src={media.href} alt={media.alt} fill />
							</CarouselItem>
						))}
					</Carousel>
					<div className={styles.content}>
						<div className={styles.contentHeader}>
							<Text
								variant={{
									base: "utility3",
									md: "utility2",
								}}
								bold>
								<Link ref={linkRef} href={PATHS.Product(product.uri!)}>
									{product.name}
								</Link>
							</Text>
							{(product?.variants?.length ?? 0) > 1 && (
								<Text
									variant={{
										base: "utility4",
										md: "utility3",
									}}
									color="secondary">
									+{(product.variants?.length ?? 1) - 1}{" "}
									<Translate value="products.colors" />
								</Text>
							)}
						</div>
						<Text
							variant={{
								base: "utility3",
								md: "utility2",
							}}>
							{product.price}
						</Text>
					</div>
					<Button href={PATHS.Product(product.uri!)} className={styles.button}>
						{t("view")}
					</Button>
				</article>
			</PopoverContent>
		</PopoverRoot>
	);
};
