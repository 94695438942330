/* eslint-disable custom-rules/no-hardcoded-strings */

import { Skeleton } from "@/components/ui/Skeleton";
import { Text } from "@/components/ui/Text";
import styles from "../index.module.css";

export const FreeShippingCostSkeleton = () => {
	return (
		<Skeleton fitContent>
			<Text className={styles.text}>Free shipping over 50 USD</Text>
		</Skeleton>
	);
};
