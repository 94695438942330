"use client";

import { Button } from "@/components/ui/button";
import { useTranslations } from "next-intl";
import { useFormStatus } from "react-dom";

export const CountrySelectorSave = () => {
	const t = useTranslations("countrySelector");
	const { pending } = useFormStatus();

	return (
		<Button isLoading={pending} type="submit" style={{ width: "100%" }}>
			{t("save")}
		</Button>
	);
};
