/* eslint-disable custom-rules/no-hardcoded-strings */

import { AmountDot } from "@/components/ui/AmountDot";
import { Skeleton } from "@/components/ui/Skeleton";
import { Text } from "@/components/ui/Text";
import styles from "./index.module.css";

export const WishlistIntroSkeleton = () => {
	return (
		<header data-layout="section" className={styles.introWrapper}>
			<div className={styles.introHeader}>
				<Skeleton fitContent>
					<Text variant="h2" bold>
						Wishlist
					</Text>
				</Skeleton>
				<Skeleton fitContent>
					<AmountDot size="small">0</AmountDot>
				</Skeleton>
			</div>
		</header>
	);
};
