"use client";

import {
	unstable_useEffectEvent,
	useLayoutMount,
	useScroll,
	type ScrollInfo,
} from "@frend-digital/ui/hooks";
import { useEffect, useState } from "react";

export const SnackbarPosition = () => {
	const [snackbarHeight, setSnackbarHeight] = useState<number | null>(null);
	useLayoutMount(() => {
		const doc = document.documentElement;
		const snackbarHeight = getComputedStyle(doc).getPropertyValue("--snackbar-height");
		setSnackbarHeight(parseFloat(snackbarHeight));

		return () => {
			doc.style.setProperty("--snackbar-visibility", "0px");
		};
	});

	const callback = unstable_useEffectEvent((info: ScrollInfo) => {
		if (!snackbarHeight) return;
		const snackbarVisibility = Math.min(info.y, snackbarHeight);

		document.documentElement.style.setProperty(
			"--snackbar-visibility",
			`${snackbarHeight - snackbarVisibility}px`,
		);
	});

	useEffect(() => {
		if (snackbarHeight) callback({ y: 0, x: 0, isBottom: false, isTop: true });
	}, [snackbarHeight, callback]);

	useScroll(callback);

	return null;
};
