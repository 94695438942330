import { clsx } from "@frend-digital/ui";
import type { ComponentProps } from "react";
import styles from "./index.module.css";

export interface FloatingTagProps extends ComponentProps<"button"> {}

export const FloatingTag = ({ className, ...rest }: FloatingTagProps) => {
	return (
		<button
			type="button"
			title="Floating image tag"
			className={clsx(styles.root, className)}
			{...rest}
		/>
	);
};
