"use client";

import {
	AccordionContent,
	AccordionIndicator,
	AccordionItem,
	AccordionRoot,
	AccordionTrigger,
} from "@/components/ui/Accordion";
import { FlagButton } from "@/components/ui/FlagButton";
import { Text } from "@/components/ui/Text";
import {
	type CountryData,
	type GroupedCountries,
	type Region,
} from "@/features/i18n/country-selector/utils";
import type { CountryAutoResponse } from "@frend-digital/centra/next";
import { useTranslations } from "next-intl";
import styles from "./index.module.css";

export const CountrySelect = ({
	userdata,
	sortedCountries,
	popularCountries,
}: {
	userdata: CountryAutoResponse | null;
	sortedCountries?: GroupedCountries;
	popularCountries?: CountryData[];
}) => {
	const t = useTranslations("i18n.country");
	const initialCountry = userdata?.country?.country;

	return (
		<fieldset className={styles.content}>
			<div className={styles.countriesHeader}>
				<Text variant="h4" bold>
					{t("header")}
				</Text>
				<Text variant="body2">{t("subHeader")}</Text>
			</div>
			{!!popularCountries?.length && (
				<div className={styles.popular}>
					{popularCountries.map((country, i) => (
						<FlagButton
							name="country"
							defaultChecked={initialCountry === country.country}
							text={country.name}
							countryCode={country.country}
							key={i}
						/>
					))}
				</div>
			)}
			<div className={styles.regions}>
				<Text variant="utility3" color="primary-dark-blue-06">
					{t("regions")}
				</Text>
				<AccordionRoot type="single" className={styles.accordionRoot}>
					{sortedCountries &&
						Object.keys(sortedCountries).map((k, i) => {
							const region = k as Region;
							return (
								<AccordionItem key={i} className={styles.accordionItem}>
									<AccordionTrigger className={styles.accordionTrigger}>
										<Text variant="body2">{region}</Text>
										<AccordionIndicator />
									</AccordionTrigger>
									<AccordionContent>
										{sortedCountries[region]?.map((country, j) => (
											<FlagButton
												className={styles.flagButton}
												text={country.name}
												countryCode={country.country}
												key={`${i}-${j}`}
												name="country"
												defaultChecked={initialCountry === country.country}
											/>
										))}
									</AccordionContent>
								</AccordionItem>
							);
						})}
				</AccordionRoot>
			</div>
		</fieldset>
	);
};
