import BlokWrap from "@/components/bloks/BlokWrap";
import type { ArticleStoryblok } from "@/generated/sb/sb-types";
import { StoryblokComponentList, type BlokProps } from "@frend-digital/storyblok";

import { PathBreadcrumbs } from "@/components/ui/PathBreadcrumbs";

export const ArticleBlok = ({ blok, preview }: BlokProps<ArticleStoryblok>) => {
	return (
		<BlokWrap editable={blok} as="main">
			{blok.top && (
				<>
					<PathBreadcrumbs />
					<StoryblokComponentList bloks={blok.top} preview={preview} isTop />
				</>
			)}
			{blok.body && <StoryblokComponentList bloks={blok.body} preview={preview} />}
		</BlokWrap>
	);
};
