import { Text } from "@/components/ui/Text";
import { clsx } from "@frend-digital/ui";
import { useTranslations } from "next-intl";
import Image from "next/image";
import type { CartItem as CartItemType } from "../hooks";
import { CartItemAmount, RemoveCartItem } from "./CartItemAmount";
import styles from "./index.module.css";

export const useTranslatedSize = (size: string) => {
	const t = useTranslations("size");

	switch (size.toUpperCase()) {
		case "XS":
			return t("xs");
		case "S":
			return t("s");
		case "M":
			return t("m");
		case "L":
			return t("l");
		case "XL":
			return t("xl");
		default:
			return size;
	}
};

export const CartItem = ({
	item,
	className,
	hideActions,
}: {
	item: CartItemType;
	className?: string;
	hideActions?: boolean;
}) => {
	const media = item.media.standard;
	const size = useTranslatedSize(item.size);
	const isOnSale = item.discountedPrice !== item.price;

	return (
		<article className={clsx(styles.root, className)} key={item.line}>
			<div className={styles.imageContainer}>
				{media && (
					<Image
						className={styles.image}
						src={media?.href}
						fill
						sizes={"50vw"}
						alt="product"
					/>
				)}
			</div>
			<div className={styles.details}>
				<dl className={styles.description}>
					<dt className="sr-only">Product Name</dt>
					<Text asChild className={styles.name} bold>
						<dd>{item.name}</dd>
					</Text>

					<dt className="sr-only">Variant</dt>
					<Text className={styles.variant} asChild>
						<dd>
							{item.variant} #{item.sku}
						</dd>
					</Text>

					<dt className="sr-only">Size</dt>
					<Text className={styles.size} asChild>
						<dd>{size}</dd>
					</Text>
				</dl>
				<div className={styles.controls}>
					<div className={clsx(styles.priceContainer, hideActions && styles.inCheckout)}>
						<Text block className={styles.price}>
							{item.price}
						</Text>
						{isOnSale && (
							<Text
								className={clsx(
									styles.priceBeforeDiscount,
									hideActions && styles.inCheckout,
								)}>
								{item.discountedPrice}
							</Text>
						)}
					</div>

					{!hideActions && <CartItemAmount line={item.line} quantity={item.quantity} />}
				</div>
			</div>
			{!hideActions && <RemoveCartItem line={item.line} />}
		</article>
	);
};
