import { Asset } from "@/components/bloks/MediaBlok";
import { Tag } from "@/components/ui/Tag";
import { Text } from "@/components/ui/Text";
import type { ArticleStoryblok } from "@/generated/sb/sb-types";
import { clsx } from "@frend-digital/ui";
import Link from "next/link";
import type { ComponentProps } from "react";
import type { ISbStoryData } from "storyblok-js-client";
import styles from "./index.module.css";

type FeaturedStoryProps = ComponentProps<"div"> & {
	story: ISbStoryData<ArticleStoryblok>;
};

export const FeaturedStory = ({ story, className, ...props }: FeaturedStoryProps) => {
	const assetSource = story?.content.headerImage
		? story?.content.headerImage
		: story?.content.image
			? story.content.image
			: undefined;

	return (
		<Link href={`/${story.full_slug}`} className={clsx(styles.contentStory, className)}>
			<div {...props}>
				<div className={styles.contentStoryImage}>
					{assetSource && <Asset source={assetSource} />}
					{story?.content.tags &&
						!!story?.content.tags.length &&
						story?.content.tags.map((tag) => (
							<Tag variant="white" className={styles.tag} size="small" key={tag}>
								{tag}
							</Tag>
						))}
				</div>

				<Text asChild variant="h4" bold>
					<h4>{story?.content.title}</h4>
				</Text>
			</div>
		</Link>
	);
};
