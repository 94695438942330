import BlokWrap from "@/components/bloks/BlokWrap";

import { FirstWordBold } from "@/components/ui/FirstWordBold";
import {
	ScrollAreaBar,
	ScrollAreaRoot,
	ScrollAreaViewport,
} from "@/components/ui/ScrollArea";
import { Text } from "@/components/ui/Text";
import type {
	ContentCardsStoryblok,
	ContentCardStoryblok,
} from "@/generated/sb/sb-types";
import {
	StoryblokComponent,
	StoryblokComponentList,
	type BlokProps,
} from "@frend-digital/storyblok";
import styles from "./index.module.css";

export const ContentCardsBlok = ({ blok, preview }: BlokProps<ContentCardsStoryblok>) => {
	return (
		<BlokWrap layout="full" editable={blok} asChild className={styles.root}>
			<div>
				<div data-layout="page">
					<Text variant="h3" className={styles.contentCardsTitle}>
						<FirstWordBold>{blok.title}</FirstWordBold>
					</Text>
				</div>
				<div className={styles.contentCardsMobile}>
					<ScrollAreaRoot type="auto">
						<ScrollAreaViewport>
							<div style={{ display: "flex", gap: "2px" }}>
								{blok.contentCards?.map((b) => (
									<StoryblokComponent blok={b} preview={preview} key={b._uid} />
								))}
							</div>
						</ScrollAreaViewport>
						<ScrollAreaBar orientation="horizontal" />
					</ScrollAreaRoot>
				</div>
				<div className={styles.contentCardsDesktop}>
					<StoryblokComponentList
						bloks={blok.contentCards as ContentCardStoryblok[]}
						preview={preview}
					/>
				</div>
			</div>
		</BlokWrap>
	);
};
