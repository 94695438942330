"use client";

import { NewsletterSignup } from "@/features/navigation/Footer/NewsletterSignup";
import type { NewsletterSignupStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import BlokWrap from "../BlokWrap";
import styles from "./index.module.css";

export const NewsletterSignupBlok = ({
	blok,
	preview,
}: BlokProps<NewsletterSignupStoryblok>) => {
	return (
		<BlokWrap data-layout="content" editable={blok}>
			<div className={styles.root}>
				<NewsletterSignup header={blok.text} subHeader={blok.text} />
			</div>
		</BlokWrap>
	);
};
