import {
	unstable_useEffectEvent,
	type ScrollInfo,
	useScroll,
} from "@frend-digital/ui/hooks";
import { useState, useRef, useCallback } from "react";

export const useStickyHeader = () => {
	const [isTop, setIsTop] = useState(true);
	const scrollYRef = useRef(0);
	const [direction, setDirection] = useState<"up" | "down">("up");

	const onScroll = unstable_useEffectEvent((scroll: ScrollInfo) => {
		if (scroll.y < 0) return setDirection("up");
		setIsTop(scroll.y <= 100);

		const direction = scroll.y > scrollYRef.current ? "down" : "up";

		// Only show fixed header when scroll direction is up and scrollY is greater than headerHeight
		setDirection(direction);

		scrollYRef.current = scroll.y;
	});

	useScroll(onScroll);

	const getHeaderProps = useCallback(
		(props: React.HTMLProps<HTMLElement>) => {
			return {
				...props,
				"data-direction": direction,
				"data-state": isTop ? "top" : "fixed",
			};
		},
		[direction, isTop],
	);

	return {
		isTop,
		direction,
		getHeaderProps,
	};
};
