"use client";

import { classed, clsx } from "@frend-digital/ui";
import { unstable_useEffectEvent } from "@frend-digital/ui/hooks";
import { type EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { useEffect, type ComponentProps, type ReactNode } from "react";
import type { IconButton } from "../iconButton";
import { NextButton, PrevButton, useActions } from "./CarouselActions";
import styles from "./index.module.css";

interface CarouselProps {
	className?: string;
	children: ReactNode;
	itemsPerSlide?: number | "ignore";
	options?: EmblaOptionsType;

	classNames?: {
		root?: string;
		viewport?: string;
		content?: string;
		prev?: string;
		next?: string;
	};

	// eslint-disable-next-line no-unused-vars
	onIndexChange?: (index: number) => void;

	pagination?:
		| boolean
		| {
				size?: ComponentProps<typeof IconButton>["size"];
		  };
}

export const CarouselItem = classed("div", styles.slide);

export const Carousel = ({
	children,
	options,
	itemsPerSlide = 1,
	className,
	classNames,
	onIndexChange,
	pagination = true,
}: CarouselProps) => {
	const [emblaRef, emblaApi] = useEmblaCarousel(options);

	const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
		useActions(emblaApi);

	useEffect(() => {
		emblaApi?.reInit(options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emblaApi]);

	const unstable_onIndexChange = unstable_useEffectEvent(onIndexChange || (() => {}));

	useEffect(() => {
		const event = emblaApi?.on("select", (api) => {
			unstable_onIndexChange?.(api.selectedScrollSnap());
		});
		return () => {
			event?.clear();
		};
	}, [emblaApi, unstable_onIndexChange]);

	const styleProps = (
		typeof itemsPerSlide === "number"
			? {
					"--slide-size": 100 / itemsPerSlide + "%",
				}
			: {}
	) as React.CSSProperties;

	const paginationSize =
		typeof pagination === "boolean" ? "xLarge" : (pagination?.size ?? "xLarge");

	return (
		<section
			style={styleProps}
			className={clsx(styles.root, className, classNames?.root)}>
			{pagination && (
				<>
					<PrevButton
						size={paginationSize}
						className={clsx(styles.control, styles.prevButton, classNames?.prev)}
						onClick={onPrevButtonClick}
						disabled={prevBtnDisabled}
					/>
					<NextButton
						size={paginationSize}
						className={clsx(styles.control, styles.nextButton, classNames?.next)}
						onClick={onNextButtonClick}
						disabled={nextBtnDisabled}
					/>
				</>
			)}

			<div className={clsx(styles.viewport, classNames?.viewport)} ref={emblaRef}>
				<div className={clsx(styles.container, classNames?.content)}>{children}</div>
			</div>
		</section>
	);
};
