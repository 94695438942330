"use client";

import { Arrow24 } from "@/components/icons";
import { Text } from "@/components/ui/Text";
import { PATHS } from "@/config";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
import styles from "./index.module.css";

interface Props {
	children: React.ReactNode;
}

export const HeaderLayout = ({ children }: Props) => {
	const pathname = usePathname();
	return (
		<>{pathname.includes("/checkout") ? <ContinueShoppingButton /> : <>{children}</>}</>
	);
};

export const ContinueShoppingButton = () => {
	const t = useTranslations("header");

	return (
		<Link href={PATHS.Products.Root} className={styles.continueShopping}>
			<Arrow24 style={{ rotate: "180deg" }} />
			<Text className={styles.continueShoppingText}>{t("continue")}</Text>
		</Link>
	);
};
