import BlokWrap from "@/components/bloks/BlokWrap";
import { SideBySide } from "@/components/ui/SideBySide";
import type { SideBySideStoryblok } from "@/generated/sb/sb-types";
import { StoryblokComponentList, type BlokProps } from "@frend-digital/storyblok";

export const SideBySideBlok = ({ blok, preview }: BlokProps<SideBySideStoryblok>) => {
	return (
		<BlokWrap layout="page" editable={blok} asChild>
			<SideBySide>
				<SideBySide.Left>
					{blok.left && <StoryblokComponentList bloks={blok.left} preview={preview} />}
				</SideBySide.Left>
				<SideBySide.Right>
					{blok.right && <StoryblokComponentList bloks={blok.right} preview={preview} />}
				</SideBySide.Right>
			</SideBySide>
		</BlokWrap>
	);
};
