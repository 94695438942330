import BlokWrap from "@/components/bloks/BlokWrap";
import { Button } from "@/components/ui/button";
import { Tag } from "@/components/ui/Tag";
import { Text } from "@/components/ui/Text";
import type { RetailerStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import { Translate } from "move-to-front/ui/Translate";
import styles from "./index.module.css";

export const RetailerBlok = ({
	blok,
	mapRef,
}: BlokProps<RetailerStoryblok> & {
	// eslint-disable-next-line no-undef
	mapRef: React.MutableRefObject<mapboxgl.Map | null>;
}) => {
	const tag = `${blok.country ?? "Country"}, ${blok.city ?? "City"}`;

	const formattedAddress = `${blok.street} ${blok.postalCode} ${blok.city}, ${blok.country}`;

	const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(blok.title + " " + formattedAddress)}`;

	return (
		<BlokWrap dataBlok={false} editable={blok} as="article" className={styles.root}>
			<div className={styles.tag}>
				<Tag size="xSmall" variant="beige">
					<Text variant="utility5" bold>
						{tag}
					</Text>
				</Tag>
			</div>
			<header>
				<Text variant="h4" bold>
					{blok.title}
				</Text>
				<Text variant="body2" color="primary-dark-blue-08" className={styles.link}>
					{blok.email}
				</Text>
			</header>
			<div className={styles.address}>
				<Text variant="body2">{formattedAddress}</Text>
				<Button href={googleMapsLink} target="_blank" variant="unstyled">
					<Text variant="body2" color="primary-dark-blue-08" className={styles.link}>
						<Translate value={"map.view"} />
					</Text>
				</Button>
			</div>
		</BlokWrap>
	);
};
