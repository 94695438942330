"use client";
import { clsx } from "@frend-digital/ui";
import { useStickyHeader } from "move-to-front/hooks/useStickyHeader";
import React, { type ComponentProps } from "react";
import styles from "./header.module.css";

interface Props extends ComponentProps<"header"> {
	children: React.ReactNode;
	/**
	 * Offset - used to calculate the scroll position from top, i.e. how long data-state="top" should be before it becomes fixed
	 */
	offset?: number;
}

export const StickyHeader = ({ children, className, ref, ...rest }: Props) => {
	const { getHeaderProps } = useStickyHeader();

	return (
		<header
			ref={ref}
			{...getHeaderProps({
				...rest,
				className: clsx(styles.header, className, "header"),
			})}>
			{children}
		</header>
	);
};
