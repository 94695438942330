"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/Input";
import { Text } from "@/components/ui/Text";
import { useSuccessMessage } from "@/features/Account/Account/hooks";
import { useSubscribeNewsletter } from "@/lib/centra/selection/fetchers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslations } from "next-intl";
import { useForm, type SubmitHandler } from "react-hook-form";
import { z } from "zod";
import styles from "./index.module.css";

export const NewsletterSignup = ({
	header,
	subHeader,
}: {
	header?: string;
	subHeader?: string;
}) => {
	const t = useTranslations("footer.newsletter");

	const newsletterSignupSchema = z.object({
		email: z.string().email({ message: t("fieldError.emailRequired") }),
	});

	type NewsletterInput = z.infer<typeof newsletterSignupSchema>;

	const subscribe = useSubscribeNewsletter();
	const { successMessage, setSuccessMessage } = useSuccessMessage(5000);

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm<NewsletterInput>({
		resolver: zodResolver(newsletterSignupSchema),
	});

	const onSubmit: SubmitHandler<NewsletterInput> = async ({ email }) => {
		subscribe.mutate(
			{
				email,
			},
			{
				onSuccess: () => {
					setSuccessMessage(t("success"));
					reset();
				},
				onError: (e) => {
					setError("email", {
						message: t("error"),
					});
				},
			},
		);
	};

	return (
		<form className={styles.newsletter} onSubmit={handleSubmit(onSubmit)}>
			<Text variant="h3" bold className={styles.header}>
				{header}
			</Text>
			<Text variant="body" className={styles.subHeader}>
				{subHeader}
			</Text>
			<Input
				className={styles.input}
				placeholder="Email"
				error={errors.email?.message}
				{...register("email", { required: true })}
				name="email"
			/>
			{successMessage && (
				<Text variant="utility5" bold type="success">
					{successMessage}
				</Text>
			)}
			<Button color="secondaryBlue" className={styles.stretch} type="submit">
				{t("register")}
			</Button>
		</form>
	);
};
