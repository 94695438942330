import BlokWrap from "@/components/bloks/BlokWrap";
import { ProductCard } from "@/features/products/ProductCard";
import type { ProductStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import type { ProductFetcherReturn } from "./actions";

export const ProductBlok = ({
	blok,
	data,
}: BlokProps<ProductStoryblok, ProductFetcherReturn>) => {
	if (!data) return null;

	return (
		<BlokWrap editable={blok} as="div">
			<ProductCard product={data} />
		</BlokWrap>
	);
};
