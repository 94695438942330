import BlokWrap from "@/components/bloks/BlokWrap";
import Wishlist from "@/features/Wishlist/Wishlist";
import type { WishlistPageStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";

export const WishlistBlok = ({ blok }: BlokProps<WishlistPageStoryblok>) => {
	return (
		<BlokWrap editable={blok} as="div">
			<Wishlist title={blok.title} />
		</BlokWrap>
	);
};
