import type { RichtextStoryblok } from "@/generated/sb/sb-types";
import { StoryblokComponent } from "@frend-digital/storyblok";
import { clsx } from "@frend-digital/ui";
import Link from "next/link";
import { type ComponentProps } from "react";
import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";
import styles from "./index.module.css";

export type RichTextProps = ComponentProps<"div"> & { small?: boolean };

export const RichText = ({
	children,
	className,
	small,
	...rest
}: ComponentProps<"div"> & { small?: boolean }) => {
	return (
		<div className={clsx(className, styles.root, small && styles.small)} {...rest}>
			{children}
		</div>
	);
};

export type RichTextRendererProps = {
	blok: RichtextStoryblok;
	className?: string;
	preview: boolean;
	small?: boolean;
};

export const RichTextRenderer = ({
	blok,
	className,
	preview,
	small,
}: RichTextRendererProps) => {
	const content = render(blok, {
		defaultBlokResolver: (name, props) => (
			<StoryblokComponent blok={{ component: name, ...props }} preview={preview} />
		),

		markResolvers: {
			[MARK_LINK]: (children, attrs) =>
				attrs.href ? (
					<Link href={attrs.href} target={attrs.target}>
						{children}
					</Link>
				) : (
					<>{children}</>
				),
		},
	});

	return (
		<RichText className={className} small={small}>
			{content}
		</RichText>
	);
};
