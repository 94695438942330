import { SideBySide } from "../SideBySide";
import { Text } from "../Text";
import styles from "./index.module.css";

export type IntroProps = Partial<{
	title: string;
	text: string;
}>;

export const Intro = ({ title, text }: IntroProps) => {
	return (
		<div data-layout="wide" className={styles.root}>
			<SideBySide className={styles.sideBySide}>
				<Text variant="h2" bold asChild>
					<h1>{title}</h1>
				</Text>
				<Text variant="body">{text}</Text>
			</SideBySide>
		</div>
	);
};
