import { Asset } from "@/components/bloks/MediaBlok";
import type { AssetStoryblok } from "@/generated/sb/sb-types";
import { getLink, type MultilinkStoryblok } from "@frend-digital/storyblok";
import Link from "next/link";
import { Text } from "../Text";
import styles from "./index.module.css";

export const ContentCard = ({
	asset,
	storyLink,
	title,
}: {
	asset?: AssetStoryblok;
	storyLink?: MultilinkStoryblok;
	title?: string;
}) => {
	if (!asset) return null;

	const href = getLink(storyLink);

	return (
		<Link
			className={styles.root}
			href={href?.href ?? "/"}
			style={{ textDecoration: "none" }}>
			<article>
				<div className={styles.contentCardImage}>
					<>{asset && <Asset source={asset} />}</>
				</div>
				<Text variant="h4" bold className={styles.contentCardTitle}>
					{title}
				</Text>
			</article>
		</Link>
	);
};
