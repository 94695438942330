import { CarouselItem } from "@/components/ui/Carousel";
import type { ProductCard as ProductCardType } from "@/lib/centra/formatters";
import { ProductCard } from "../ProductCard";
import { ProductCardSkeleton } from "../ProductCard/ProductCardSkeleton";
import { CarouselWrapper, type CarouselProps } from "./CarouselWrapper";

export interface ProductCarouselProps extends Omit<CarouselProps, "children"> {
	products: ProductCardType[];
	className?: string;
}

export const ProductCarousel = ({ products, ...rest }: ProductCarouselProps) => {
	return (
		<CarouselWrapper pagination={products.length > 4} {...rest}>
			{products.map((p, index) => (
				<CarouselItem key={index}>
					<ProductCard product={p} />
				</CarouselItem>
			))}
		</CarouselWrapper>
	);
};

export const ProductCarouselSkeleton = ({ count }: { count: number }) => {
	return (
		<div data-layout={count <= 4 ? "page" : "full"}>
			<ProductCardSkeleton />
			<ProductCardSkeleton />
			<ProductCardSkeleton />
			<ProductCardSkeleton />
		</div>
	);
};
