import { AddToWishlistButton } from "@/components/ui/AddToWishlistButton";
import { Text } from "@/components/ui/Text";
import type { ProductCard as IProductCard } from "@/lib/centra/formatters";
import { Translate } from "move-to-front/ui/Translate";
import styles from "./index.module.css";
import { ProductCardImage } from "./ProductCardImage";
import { ProductCardLink } from "./ProductCardLink";
import { ProductVariantsDisplay } from "./ProductCardVariants";
import { ProductImageProvider } from "./ProductDisplayProvider";
import { ProductCardTags } from "./ProductTags";

export const ProductCard = ({ product }: { product: IProductCard }) => {
	const tag = product.tags?.[0];
	return (
		<ProductImageProvider>
			<ProductCardLink href={`/product/${product.uri}`}>
				<div className={styles.cardBody} data-name="productCard">
					<div className={styles.overlay}>
						<ProductCardTags
							onSale={product.onSale}
							discountPercent={product.discountPercent}
							className={styles.cardTag}
							tags={tag ? [{ color: "white", text: tag }] : []}
						/>
						{product.variants && product.variants.length && (
							<ProductVariantsDisplay
								currentProduct={{
									uri: product.uri!,
									title: product.variantName!,
								}}
								variants={product.variants}
							/>
						)}
					</div>

					<ProductCardImage media={product.media} />
				</div>
				<div className={styles.cardFooter}>
					<dl className={styles.footerInfo}>
						<dt className="sr-only">Product name</dt>
						<Text asChild variant="h5" bold className={styles.productName}>
							<dd>{product.name}</dd>
						</Text>
						<dt className="sr-only">Price</dt>
						<Text asChild variant="h5" className={styles.productPrice}>
							<dd>
								{product.price}
								{product.onSale && (
									<span className={styles.priceBeforeDiscount}>
										{product.priceBeforeDiscount}
									</span>
								)}
							</dd>
						</Text>
						<dt className="sr-only">Variants</dt>
						<Text asChild variant="h5" className={styles.variantAmount}>
							<dd>
								+{product.variants?.length} <Translate value={"products.colors"} />
							</dd>
						</Text>
					</dl>

					<AddToWishlistButton
						forceMount={false}
						itemId={product.id}
						className={styles.favourite}
					/>
				</div>
			</ProductCardLink>
		</ProductImageProvider>
	);
};
