"use client";

import { TabBar } from "@/components/ui/TabBar";
import type { Dispatch, SetStateAction } from "react";

interface SliderTabBarProps {
	activeTab: string;
	setActiveTab: Dispatch<SetStateAction<string>>;
	tabs?: { name: string; value: string }[];
}

export const SliderTabBar = ({ activeTab, setActiveTab, tabs }: SliderTabBarProps) => {
	if (!tabs) return null;

	return (
		<TabBar value={activeTab} onValueChange={setActiveTab} size="large" tabs={tabs} />
	);
};
