"use client";

import { AmountDot } from "@/components/ui/AmountDot";
import { Text } from "@/components/ui/Text";
import styles from "./index.module.css";

export const WishListIntro = ({ amount, title }: { amount: number; title?: string }) => {
	return (
		<header data-layout="section" className={styles.introWrapper}>
			<div className={styles.introHeader}>
				<Text variant="h2" bold>
					{title}
				</Text>
				<AmountDot size="small">{amount}</AmountDot>
			</div>
		</header>
	);
};
