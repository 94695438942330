import BlokWrap from "@/components/bloks/BlokWrap";
import { AccordionRoot } from "@/components/ui/Accordion";
import type { AccordionStoryblok } from "@/generated/sb/sb-types";
import { StoryblokComponentList, type BlokProps } from "@frend-digital/storyblok";
import styles from "./index.module.css";

export const AccordionBlok = ({ blok, preview }: BlokProps<AccordionStoryblok>) => {
	return (
		<BlokWrap editable={blok} data-layout="section" as="div" className={styles.root}>
			<AccordionRoot type="single" className={styles.items}>
				{blok.items && <StoryblokComponentList bloks={blok.items} preview={preview} />}
			</AccordionRoot>
		</BlokWrap>
	);
};
