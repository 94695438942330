"use client";

import type { useLocationAction } from "@frend-digital/centra/location";
import { createContext, useContext } from "react";

type LocationState = ReturnType<typeof useLocationAction>;

interface LocationContext {
	state: LocationState[0];
	action: LocationState[1];
	pending: LocationState[2];
	trigger: () => void;
}

export const LocationContext = createContext<LocationContext>(null!);

export const useLocationContext = () => {
	return useContext(LocationContext);
};
