import BlokWrap from "@/components/bloks/BlokWrap";
import type { ProductPageStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import { StoryblokComponentList } from "@frend-digital/storyblok";

export const ProductPageBlok = ({ blok, preview }: BlokProps<ProductPageStoryblok>) => {
	return (
		<BlokWrap editable={blok} as="div">
			{blok.body && <StoryblokComponentList bloks={blok.body} preview={preview} />}
		</BlokWrap>
	);
};
