"use client";

import { Arrow24, Arrow32 } from "@/components/icons";
import { type EmblaCarouselType } from "embla-carousel";
import { useCallback, useEffect, useState, type ComponentProps } from "react";
import { IconButton } from "../iconButton";

export type UseActionsType = {
	prevBtnDisabled: boolean;
	nextBtnDisabled: boolean;
	onPrevButtonClick: () => void;
	onNextButtonClick: () => void;
};

export const useActions = (emblaApi: EmblaCarouselType | undefined): UseActionsType => {
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

	const onPrevButtonClick = useCallback(() => {
		if (!emblaApi) return;
		emblaApi.scrollPrev();
	}, [emblaApi]);

	const onNextButtonClick = useCallback(() => {
		if (!emblaApi) return;
		emblaApi.scrollNext();
	}, [emblaApi]);

	const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
		setPrevBtnDisabled(!emblaApi.canScrollPrev());
		setNextBtnDisabled(!emblaApi.canScrollNext());
	}, []);

	useEffect(() => {
		if (!emblaApi) return;

		onSelect(emblaApi);
		emblaApi.on("reInit", onSelect).on("select", onSelect);
	}, [emblaApi, onSelect]);

	return {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick,
	};
};

export type ActionButtonProps = Omit<ComponentProps<typeof IconButton>, "color">;

export const PrevButton = ({
	children,
	size = "xLarge",
	...restProps
}: ActionButtonProps) => {
	return (
		<>
			<IconButton {...restProps} size={size} color="white">
				{size === "xLarge" ? (
					<Arrow32 style={{ transform: "rotate(180deg)" }} />
				) : (
					<Arrow24 style={{ transform: "rotate(180deg)" }} />
				)}
			</IconButton>
			{children}
		</>
	);
};

export const NextButton = ({
	children,
	size = "xLarge",
	...restProps
}: ActionButtonProps) => {
	return (
		<>
			<IconButton {...restProps} size={size} color="white">
				{size === "xLarge" ? <Arrow32 /> : <Arrow24 />}
			</IconButton>
			{children}
		</>
	);
};
