import { classed, clsx } from "@frend-digital/ui";
import { type ComponentProps } from "react";
import { SideBySideProvider } from "./context";
import styles from "./index.module.css";

interface SideBySideProps extends ComponentProps<"div"> {}

export const SideBySide = ({ className, children, ...rest }: SideBySideProps) => {
	return (
		<>
			<section className={clsx(styles.root, className)} {...rest}>
				<SideBySideProvider>{children}</SideBySideProvider>
			</section>
		</>
	);
};

const Left = classed("article", styles.left, styles.side, "left");
const Right = classed("article", styles.right, styles.side, "right");

SideBySide.Left = Left;
SideBySide.Right = Right;
