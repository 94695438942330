import { useEffect, useState } from "react";

export const useSuccessMessage = (timeout?: number) => {
	const [successMessage, setSuccessMessage] = useState<string>("");

	useEffect(() => {
		if (successMessage && timeout) {
			const timer = setTimeout(() => {
				setSuccessMessage("");
			}, timeout);

			return () => clearTimeout(timer);
		}
	}, [successMessage, timeout]);

	return { successMessage, setSuccessMessage };
};
