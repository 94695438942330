import BlokWrap from "@/components/bloks/BlokWrap";
import { Button } from "@/components/ui/button";
import { Text } from "@/components/ui/Text";
import type { TagCloudStoryblok } from "@/generated/sb/sb-types";
import { getLink, type BlokProps } from "@frend-digital/storyblok";
import styles from "./index.module.css";

export const TagCloudBlok = ({ blok }: BlokProps<TagCloudStoryblok>) => {
	return (
		<BlokWrap data-layout="section" editable={blok} className={styles.root}>
			<header className={styles.header}>
				<Text variant="h3" bold>
					{blok.title}
				</Text>
			</header>
			<div className={styles.items}>
				{" "}
				{blok.items?.map((item) => {
					const link = getLink(item.link);
					return (
						<Button
							key={item._uid}
							href={link?.href}
							size={{
								base: "medium",
								md: "xLarge",
							}}
							color="white">
							{item.text}
						</Button>
					);
				})}
			</div>
		</BlokWrap>
	);
};
