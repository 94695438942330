"use client";

import { Select } from "@/components/ui/Select";
import { Text } from "@/components/ui/Text";
import { LocationInputs } from "@frend-digital/centra/location";
import { useLocale, useTranslations } from "next-intl";
import { useRef } from "react";
import { LOCALES } from "../../../i18n/config";
import { useLocationContext } from "./context";
import styles from "./index.module.css";

export const LanguageSelect = () => {
	const locale = useLocale();
	const t = useTranslations("i18n.language");

	const contentRef = useRef<HTMLDivElement>(null!);
	const { trigger } = useLocationContext();

	return (
		<div className={styles.content} ref={contentRef}>
			<Text variant="h4" bold>
				{t("header")}
			</Text>
			<Text variant="body2">{t("subHeader")}</Text>
			<Select
				onValueChange={(value) => {
					if (value === locale) return;
					trigger();
				}}
				large
				hideReset
				placeholder={t("placeholder")}
				name={LocationInputs.language}
				defaultValue={locale}
				items={LOCALES.map((l) => ({
					name: t(l),
					value: l,
				}))}
			/>
		</div>
	);
};
