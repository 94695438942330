/* eslint-disable custom-rules/no-hardcoded-strings */

import { Progress } from "@/components/ui/Progress";
import { Skeleton } from "@/components/ui/Skeleton";
import { Text } from "@/components/ui/Text";
import styles from "./index.module.css";

export const ShippingBarSkeleton = () => {
	return (
		<section className={styles.shipping}>
			<div className={styles.shippingTotal}>
				<Skeleton fitContent>
					<Text
						variant={{
							base: "utility6",
							md: "utility5",
						}}>
						Shipping
					</Text>
				</Skeleton>
				<Skeleton fitContent>
					<Text
						variant={{
							base: "utility4",
							md: "utility1",
						}}
						bold>
						00 NOK
					</Text>
				</Skeleton>
			</div>

			<div className={styles.amountLeft}>
				<div>
					<Skeleton fitContent>
						<Text
							variant={{
								base: "utility5",
								md: "utility4",
							}}>
							0 NOK left for{" "}
							<Text asChild bold variant="none">
								<span>free shipping</span>
							</Text>
						</Text>
						<Progress max={100} value={80} />
					</Skeleton>
				</div>
			</div>
		</section>
	);
};
