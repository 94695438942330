"use client";

import { Close24xLarge } from "@/components/icons";
import {
	ModalContent,
	ModalHeader,
	ModalRoot,
	ModalViewport,
} from "@/components/ui/modal";
import { Text } from "@/components/ui/Text";
import { useModal } from "@/hooks/useModal";
import { Slot } from "@frend-digital/ui";
import { useTranslations } from "next-intl";
import { Suspense, type ComponentProps, type ReactNode } from "react";
import styles from "./index.module.css";

export const UnsuspendedShippingModalTrigger = ({
	asChild,
	children,
	...rest
}: ComponentProps<"button"> & { asChild?: boolean }) => {
	const { open } = useModal("shipping");

	const Component = asChild ? Slot : "button";

	return (
		<Component
			onClick={() => {
				open();
			}}
			{...rest}>
			{children}
		</Component>
	);
};

export const ShippingModalTrigger: typeof UnsuspendedShippingModalTrigger = (props) => {
	return (
		<Suspense fallback={props.asChild ? props.children : null}>
			<UnsuspendedShippingModalTrigger {...props} />
		</Suspense>
	);
};

export type ShippingModalProps = {
	children: ReactNode;
	title?: string;
};

export const ShippingModal = ({ children, title }: ShippingModalProps) => {
	const { isOpen, setOpen } = useModal("shipping");
	const t = useTranslations("product");

	return (
		<ModalRoot open={isOpen} onOpenChange={setOpen}>
			<ModalViewport>
				<ModalContent>
					<article className={styles.productModalBody}>
						<ModalHeader className={styles.header}>
							<Text className={styles.modalHeader} asChild>
								<h2>{title}</h2>
							</Text>
							<Close24xLarge
								className={styles.productModalCloseIcon}
								onClick={() => setOpen(!isOpen)}
							/>
						</ModalHeader>
						<div className={styles.content}>{children}</div>
					</article>
				</ModalContent>
			</ModalViewport>
		</ModalRoot>
	);
};
