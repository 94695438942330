import styles from "./index.module.css";

export const FirstWordBold = ({ children }: { children?: string }) => {
	if (!children) return;

	const sentence = children.split(" ");
	return (
		<>
			<span className={styles.bold}>{sentence?.slice(0, 1).pop()} </span>
			{sentence?.slice(1, sentence.length).join(" ")}
		</>
	);
};
