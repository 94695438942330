import BlokWrap from "@/components/bloks/BlokWrap";
import {
	AccordionContent,
	AccordionIndicator,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/Accordion";
import { RichTextRenderer } from "@/components/ui/RichText";
import { Text } from "@/components/ui/Text";
import type { AccordionItemStoryblok } from "@/generated/sb/sb-types";
import { type BlokProps } from "@frend-digital/storyblok";
import { clsx } from "@frend-digital/ui";
import styles from "./index.module.css";

export const AccordionItemBlok = ({
	blok,
	preview,
}: BlokProps<AccordionItemStoryblok>) => {
	return (
		<BlokWrap editable={blok} dataBlok={false} as="div" className={clsx(styles.root)}>
			<AccordionItem className={styles.item}>
				<AccordionTrigger className={styles.trigger}>
					<Text asChild variant="body2">
						<span>{blok.title}</span>
					</Text>
					<AccordionIndicator />
				</AccordionTrigger>
				<AccordionContent className={styles.content}>
					{blok.content && (
						<RichTextRenderer small blok={blok.content} preview={preview} />
					)}
				</AccordionContent>
			</AccordionItem>
		</BlokWrap>
	);
};
