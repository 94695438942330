"use client";

import { Text } from "@/components/ui/Text";
import { useFreeShippingCost } from "@/lib/centra/hooks";
import { useTranslations } from "next-intl";
import styles from "../index.module.css";
import { FreeShippingCostSkeleton } from "./FreeShippingCostSkeleton";

export const FreeShippingCost = () => {
	const t = useTranslations("shipping.free");
	const { isLoading, error, freeShippingCost, freeShippingCurrency } =
		useFreeShippingCost();

	if (isLoading) {
		return <FreeShippingCostSkeleton />;
	}

	if (error) {
		return null;
	}

	return (
		<Text className={styles.text}>
			{t("text")} {freeShippingCost} {freeShippingCurrency}
		</Text>
	);
};
