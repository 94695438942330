"use client";

import BlokWrap from "@/components/bloks/BlokWrap";
import { RetailerFilterBar } from "@/components/ui/RetailerFilterBar";
import type { RetailersStoryblok } from "@/generated/sb/sb-types";
import { type BlokProps } from "@frend-digital/storyblok";

import { type MapboxFeature } from "move-to-front/features/Map/types";
import { useMemo, useRef } from "react";
import { RetailerBlok } from "../RetailerBlok";
import type { FetchRetailersData } from "./actions";
import styles from "./index.module.css";

export type CityAndCountry = {
	city?: string;
	country?: string;
};

export const RetailersBlok = ({
	blok,
	preview,
	searchParams,
	data,
}: BlokProps<RetailersStoryblok, FetchRetailersData> & {
	searchParams?: CityAndCountry;
}) => {
	// eslint-disable-next-line no-undef
	const mapRef = useRef<mapboxgl.Map | null>(null);

	const city = searchParams?.city;
	const country = searchParams?.country;

	const reducedData = useMemo(() => {
		return data?.reduce(
			(acc, retailer) => {
				const blok = retailer.content;

				if (blok.city && blok.country) {
					const matchCity = city ? blok.city === city : true;
					const matchCountry = country ? blok.country === country : true;

					if (matchCity && matchCountry) {
						if (blok.latitude && blok.longitude) {
							const formattedAddress = `${blok.street ?? "Street"} ${blok.postalCode ?? "Postalcode"} ${blok.city ?? "City"}, ${blok.country ?? "Country"}`;

							acc.features.push({
								type: "Feature",
								geometry: {
									type: "Point",
									coordinates: [Number(blok.longitude), Number(blok.latitude)],
								},
								properties: {
									title: retailer.content.title ?? "Retailer",
									description: formattedAddress,
								},
							});
						}

						acc.bloks.push(blok);
					}

					acc.locations.push({ city: blok.city, country: blok.country });
				}
				return acc;
			},
			{ bloks: [], features: [], locations: [] } as {
				bloks: (typeof data)[number]["content"][];
				features: MapboxFeature[];
				locations: CityAndCountry[];
			},
		);
	}, [city, country, data]);

	if (!reducedData) return null;

	const { bloks, features, locations } = reducedData;

	return (
		<BlokWrap editable={blok} as="div">
			<section data-layout="section" className={styles.root}>
				{/* <RetailersMap ref={mapRef} features={features} /> */}
				<RetailerFilterBar locations={locations} />
				<div className={styles.retailers}>
					{bloks.map((blok, index) => {
						return (
							<RetailerBlok
								mapRef={mapRef}
								blok={blok}
								preview={preview}
								key={blok._uid + index}
							/>
						);
					})}
				</div>
			</section>
		</BlokWrap>
	);
};
