import BlokWrap from "@/components/bloks/BlokWrap";
import { Text } from "@/components/ui/Text";
import { Button } from "@/components/ui/button";
import type { DisplayCardStoryblok } from "@/generated/sb/sb-types";
import { sizes } from "@/lib/utils";
import { getLink, getMedia, type BlokProps } from "@frend-digital/storyblok";
import Image from "next/image";
import Link from "next/link";
import styles from "./index.module.css";

export const DisplayCardBlok = ({ blok }: BlokProps<DisplayCardStoryblok>) => {
	const backgroundImage = blok.backgroundImage ? getMedia(blok.backgroundImage) : null;

	const link = getLink(blok.link);

	return (
		<BlokWrap editable={blok} as="div" className={styles.root} dataBlok={false}>
			<Link href={link?.href ?? "/"}>
				<div className={styles.overlay}>
					<Text variant="h4" bold className={styles.header}>
						{blok.header}
					</Text>
					<Text variant="display1" bold className={styles.title}>
						{blok.title}
					</Text>
					<Button className={styles.button} size="medium" color="greige">
						<Text variant="utility3">{blok.linkText}</Text>
					</Button>
				</div>
				{backgroundImage?.url && (
					<Image
						sizes={sizes(100, 50)}
						fill
						src={backgroundImage.url}
						alt={backgroundImage?.alt ?? `${blok.title}-backgroundImage`}
					/>
				)}
			</Link>
		</BlokWrap>
	);
};
