import { create } from "zustand";

type MobileMenuState = {
	isOpen: boolean;
	close: () => void;
	open: () => void;
	toggle: () => void;
};

export const useMobileMenu = create<MobileMenuState>((set) => ({
	isOpen: false,
	close: () =>
		set({
			isOpen: false,
		}),
	open: () =>
		set({
			isOpen: true,
		}),
	toggle: () =>
		set((state) => ({
			isOpen: !state.isOpen,
		})),
}));
