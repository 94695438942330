import { CountryInput } from "@frend-digital/centra/location";
import { clsx } from "@frend-digital/ui";
import Image from "next/image";
import { type ComponentProps } from "react";
import styles from "./index.module.css";

type FlagButtonProps = ComponentProps<"input"> & {
	text?: string;
	countryCode?: string;
};

export const FlagButton = ({ text, countryCode, ...props }: FlagButtonProps) => {
	return (
		<label className={clsx(styles.root, props.className)}>
			<div className={styles.main}>
				{countryCode && (
					<div className={styles.flagImage}>
						<Image
							src={`https://flagcdn.com/w80/${countryCode.toLowerCase()}.png`}
							alt=""
							width={64}
							height={64}
						/>
					</div>
				)}

				<CountryInput
					value={countryCode}
					{...props}
					className={styles.radioButton}
					type="radio"
				/>
				<span className={clsx(styles.text)}>{text}</span>
			</div>
		</label>
	);
};
