import BlokWrap from "@/components/bloks/BlokWrap";
import type { DisplayCardsStoryblok } from "@/generated/sb/sb-types";
import { StoryblokComponentList, type BlokProps } from "@frend-digital/storyblok";
import styles from "./index.module.css";

export const DisplayCardsBlok = ({ blok, preview }: BlokProps<DisplayCardsStoryblok>) => {
	if (!blok.cards) return null;

	return (
		<BlokWrap editable={blok} as="div" className={styles.root} layout="full">
			<StoryblokComponentList bloks={blok.cards} preview={preview} />
		</BlokWrap>
	);
};
