/* eslint-disable custom-rules/no-hardcoded-strings */
import { clsx } from "@frend-digital/ui";
import styles from "./index.module.css";

export const Progress = ({
	className,
	value,
	max,
	...rest
}: React.ComponentProps<"div"> & {
	value?: number;
	max?: number;
}) => {
	return (
		<div
			className={clsx(styles.root, className)}
			{...rest}
			role="progressbar"
			aria-valuenow={value}
			aria-valuemax={max}
			aria-valuemin={0}>
			<div
				style={{
					width: `${value}%`,
				}}
				className={styles.progress}
			/>
			<span aria-live="polite" className="sr-only">
				{value}%
			</span>
		</div>
	);
};
