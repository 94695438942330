import BlokWrap from "@/components/bloks/BlokWrap";
import { FloatingTag } from "@/components/ui/FloatingTag";
import { ProductCard } from "@/features/products/ProductCard";
import { TaggedProduct } from "@/features/products/TaggedProduct";
import type { BlokProps } from "@frend-digital/storyblok";
import { clsx } from "@frend-digital/ui";
import { Asset, getAspectRatioClass, type BaseMediaProps } from "../MediaBlok";
import type { ExtendedTaggedImageStoryblok, TaggedImageData } from "./actions";
import styles from "./index.module.css";

export const TaggedImageBlok = ({
	blok,
	data,
	aspectRatio: aspectRatioOverride,
	sizes = "100vw",
}: BlokProps<ExtendedTaggedImageStoryblok, TaggedImageData> & BaseMediaProps) => {
	const aspectRatio = getAspectRatioClass(aspectRatioOverride || blok.aspectRatio);

	return (
		<BlokWrap
			layout="full"
			editable={blok}
			as="div"
			className={clsx(styles.root, aspectRatio)}>
			{blok.image?.media && <Asset sizes={sizes} source={blok.image?.media.asset} />}

			{blok.largeCardsOnDesktop && (
				<div
					className={styles.productCards}
					style={{
						justifyContent:
							blok?.alignProductCards === "right" ? "flex-end" : "flex-start",
					}}>
					{data?.map(({ product }) => (
						<div key={product.id} className={styles.productCard}>
							<ProductCard product={product} />
						</div>
					))}
				</div>
			)}

			<section
				className={clsx(
					styles.tags,
					blok.largeCardsOnDesktop && styles.hideOnLargeDisplay,
				)}>
				<ImageTags data={data} />
			</section>
		</BlokWrap>
	);
};

export const ImageTags = ({ data }: { data?: TaggedImageData }) => {
	return (
		<>
			{data?.map(({ tag, product }, index) => (
				<TaggedProduct key={index} product={product}>
					<FloatingTag
						className={styles.tag}
						style={{
							position: "absolute",
							top: `${tag?.y}%`,
							left: `${tag?.x}%`,
						}}
					/>
				</TaggedProduct>
			))}
		</>
	);
};
