import BlokWrap from "@/components/bloks/BlokWrap";
import { Intro } from "@/components/ui/Intro";
import type { IntroStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";
import { clsx } from "@frend-digital/ui";
import styles from "./index.module.css";

export const IntroBlok = ({
	blok,
	isTop,
}: BlokProps<IntroStoryblok> & {
	isTop?: boolean;
}) => {
	return (
		<BlokWrap
			editable={blok}
			as="div"
			className={clsx(styles.root, isTop && styles.isTop)}>
			<Intro title={blok.title} text={blok.text} />
		</BlokWrap>
	);
};
