import BlokWrap from "@/components/bloks/BlokWrap";
import { Button } from "@/components/ui/button";
import { FirstWordBold } from "@/components/ui/FirstWordBold";
import { Text } from "@/components/ui/Text";
import { type HeroStoryblok } from "@/generated/sb/sb-types";
import { sizes } from "@/lib/utils";
import { getLink, type BlokProps } from "@frend-digital/storyblok";
import { clsx } from "@frend-digital/ui";
import { Asset } from "../MediaBlok";
import styles from "./index.module.css";

export const HeroBlok = ({
	blok,
	isTop,
}: BlokProps<HeroStoryblok> & { isTop?: boolean }) => {
	return (
		<BlokWrap
			editable={blok}
			layout="full"
			as="div"
			className={clsx(styles.root, isTop && styles.isTop, isTop && "full-bleed")}>
			<div className={styles.content}>
				<div data-layout="page" className={styles.contentInner}>
					<div className={styles.contentContainer}>
						<Text variant="h3" className={styles.subtitle}>
							<FirstWordBold>{blok.subtitle}</FirstWordBold>
						</Text>
						<Text
							variant="display1"
							asChild
							className={styles.title}
							style={{ marginTop: 0 }}>
							<h1>{blok.title}</h1>
						</Text>
						<div className={styles.actions}>
							{blok.actions?.map((a) => {
								const href = getLink(a.link);

								return (
									<Button
										key={a._uid}
										href={href?.href ?? "/"}
										size={{
											base: "medium",
											md: "xLarge",
										}}
										color="white">
										<Text variant="utility1" bold asChild>
											<span>{a.text}</span>
										</Text>
									</Button>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.image_container}>
				{blok.desktop_media && (
					<div data-viewport="desktop" style={{ height: "100%", width: "100%" }}>
						<Asset
							sizes={sizes(100, 100)}
							className={styles.asset}
							source={blok.desktop_media}
						/>
					</div>
				)}
				{blok.mobile_media && (
					<div data-viewport="mobile" style={{ height: "100%", width: "100%" }}>
						<Asset
							sizes={sizes(100, 100)}
							className={styles.asset}
							source={blok.mobile_media}
						/>
					</div>
				)}
			</div>
		</BlokWrap>
	);
};
